<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <div
        class="d-flex justify-content-end"
        style="gap: 5px"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          v-b-modal.modal-success
          variant="outline-success"
          class="btn-sm"
          @click="handleModalExcel"
        >
          <feather-icon
            icon="FileTextIcon"
            size="16"
          />
          Nhập file Excel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-edit
          variant="outline-danger"
          class="btn-sm"
          @click="handleEdit(false)"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          {{ $t('Add') }}
        </b-button>
      </div>
    </b-col>

    <b-col cols="12">
      <b-card-code title="">
        <b-row class="form_custom">
          <b-col cols="2">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col md="3">
            <label for="">
              {{ $t('Search') }}
            </label>
            <b-form-input
              v-model="filter.search"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
          <b-col md="3">
            <label for="">
              {{ $t("source") }}
            </label>
            <b-form-select
              v-model="filter.source"
              :options="sourceOption"
              @change="loadItems"
            />
          </b-col>
          <b-col md="2">
            <label for="">
              {{ $t('status') }}
            </label>
            <b-form-select
              v-model="filter.status"
              :options="filter.statusOptions"
              @change="loadItems"
            />
          </b-col>
          <b-col md="2">
            <label for="">
              {{ $t('course_interest') }}
            </label>
            <b-form-select
              v-model="filter.type"
              :options="typeOptions"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th>
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th scope="col">
                    STT
                  </th>
                  <th
                    scope="col"
                  >
                    {{ $t('name') }}
                  </th>
                  <th
                    scope="col"
                  >
                    {{ $t('email') }}
                  </th>
                  <th scope="col">
                    {{ $t('phone') }}
                  </th>
                  <th scope="col">
                    {{ $t('source') }}
                  </th>
                  <th
                    scope="col"
                    :aria-sort="getSortDirection('created_at')"
                    @click="changeSortDirection('created_at')"
                  >
                    {{ $t('date_register') }}
                  </th>
                  <th scope="col">
                    {{ $t('course_interest') }}
                  </th>
                  <th>{{ $t('consulted') }}</th>
                  <th>{{ $t('setting') }}</th>
                </tr>
              </thead>
              <template v-if="rows.length">
                <template v-if="isLoading">
                  <tr
                    v-for="(item) in rows"
                  >
                    <td colspan="1">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="6">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="3">
                      <b-skeleton animation="fade" />
                    </td>
                  </tr>
                </template>
                <tr
                  v-for="(item, index) in rows"
                  v-else
                  :id="item.id"
                  :key="item.id"
                >
                  <td>
                    <b-form-checkbox
                      v-model="ids"
                      :value="item.id"
                      class="custom-control-danger"
                      name="check-button"
                    />
                  </td>
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td
                    class="cursor-pointer"
                    @click="handleEdit(item)"
                  ><template class="text_name">
                    {{ item.name }}
                  </template>
                  </td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    {{ item.source ? _.find(sourceOption, { 'value': parseInt(item.source) }).text : '' }}
                  </td>
                  <td>{{ convertDate(item.created_at) }}</td>
                  <td>
                    <template v-if="item.type">
                      {{ _.find(typeOptions, { 'value': parseInt(item.type) }).text }}
                    </template>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="item.status"
                      class="custom-control-danger"
                      name="check-button"
                      switch
                      @change="val => changeStatus(item.id, val)"
                    />
                  </td>
                  <td>
                    <b-dropdown
                      id="dropdown-1"
                      variant="flat-sencondary"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>
                      <b-dropdown-item
                        @click="handleEdit(item)"
                      >{{ $t('edit') }}</b-dropdown-item>
                      <b-dropdown-item
                        @click="deleteMulti(item.id)"
                      >{{ $t('Delete') }}</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <tr
                v-else
              >
                <td colspan="9">
                  <p class="text-center">
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
              <!--              </draggable>-->
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
    </b-col>
    <b-modal
      id="modal-edit"
      v-model="modalShow"
      cancel-variant="outline-secondary"
      :ok-title="$t('save_info')"
      cancel-title="Huỷ"
      centered
      :title="modalName"
      size="lg"
      @ok.prevent="submit"
    >
      <validation-observer ref="addContact">
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="Họ tên"
              rules="required"
            >
              <b-form-group>
                <label :class="errors.length > 0 ? 'text-danger': null">
                  {{ $t('name') }} <span class="text-danger">(*)</span></label>
                <b-form-input
                  id="name"
                  v-model="frm.name"
                  :state="errors.length > 0 ? false:null"
                  type="text"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label>{{ $t('email') }}</label>
              <b-form-input
                id="email"
                v-model="frm.email"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label>{{ $t('phone') }}</label>
              <b-form-input
                id="phone"
                v-model="frm.phone_number"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label>{{ $t('course_interest') }}</label>
              <b-form-select
                id="level"
                v-model="frm.type"
                :options="typeOptions"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="nguồn"
              rules="required"
            >
              <b-form-group>
                <label :class="errors.length > 0 ? 'text-danger': null">
                  {{ $t('source') }} <span class="text-danger">(*)</span></label>
                <b-form-select
                  v-model="frm.source"
                  :options="sourceOption"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label>{{ $t('note_u') }}</label>
              <b-form-textarea
                id="content"
                v-model="frm.content"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label>{{ $t('consulted') }}</label>
              <b-form-checkbox
                id="status"
                v-model="frm.status"
                class="custom-control-danger"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer="{ ok, cancel, hide }">
        <!-- Button with custom close trigger value -->
        <b-button
          variant="outline-secondary"
          @click="hide('forget')"
        >
          {{ $t('cancel') }}
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click.prevent="ok()"
        >
          {{ $t('save_info') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="modalShowExcel"
      cancel-variant="outline-secondary"
      cancel-title="Huỷ"
      centered
      title="Nhập file Excel"
      size="lg"
      @ok.prevent="handleUploadContact"
    >
      <b-row>
        <b-col md="12">
          <b-form-group>
            <div>
              <a
                :href="endPoint + '/excels/danh_sach_tai_khoan_tu_van.xlsx'"
                class="float-right mb-2"
              >
                <feather-icon icon="DownloadIcon" />
                Tải file mẫu </a>
              <b-form-file
                v-model="frm.fileExcel"
                placeholder="Tải file hoặc kéo và thả Excel"
                accept=".xlsx, .xls, .csv"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BFormCheckbox,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BSkeleton,
  BFormFile,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import config from '@/config'

export default {
  components: {
    BCardCode,
    ValidationObserver,
    ValidationProvider,
    BSkeleton,
    BRow,
    BCol,
    BFormFile,
    draggable,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPagination,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      modalShow: false,
      modalShowExcel: false,
      currentPage: 1,
      pageLength: 10,
      dragging: false,
      sourceOption: config.source,
      levelData: [
        {
          label: this.$t('kh7'),
          value: 1,
        },
        {
          label: this.$t('khZoom'),
          value: 2,
        },
        {
          label: this.$t('khOnline'),
          value: 3,
        },
      ],
      frm: {
        name: '',
        email: '',
        phone_number: '',
        type: '',
        content: '',
        source: null,
        status: true,
        fileExcel: null,
      },
      // eslint-disable-next-line global-require
      required,
      isLoading: false,
      total: 0,
      rows: [],
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      ids: [],
      modalName: '',
      typeOptions: [
        { value: 'all', text: this.$t('all') },
        { value: 1, text: this.$t('kh7') },
        { value: 2, text: this.$t('khZoom') },
        { value: 3, text: this.$t('khOnline') },
        { value: null, text: '' },
      ],
      filter: {
        search: '',
        status: 'all',
        statusOptions: [
          { value: 'all', text: this.$t('all') },
          { value: 1, text: this.$t('consulted') },
          { value: 0, text: this.$t('not_consu') },
        ],
        level: 'all',
        type: 'all',
        source: 'all',
        levelOptions: [
          { value: 'all', text: 'Tất cả' },
        ],
      },
      sort: {
        by: 'id',
        direction: 'desc',
      },
    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
    this.levelData.forEach(item => {
      this.filter.levelOptions.push({
        value: item.value,
        text: item.label,
      })
    })
  },
  methods: {
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('message.Yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/contacts', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              console.log('e', e)
              this.$bvToast.toast(this.$t('delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
              this.loadItems()
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }

      console.log('this.ids', this.ids)
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      console.log('value', value)
      console.log('this.rows[value.oldIndex]', this.rows[value.oldIndex])
      console.log('newOrder', this.rows[value.newIndex])
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].order)
      }
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/contacts', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          search: this.filter.search,
          status: this.filter.status,
          levels_id: this.filter.level,
          order_by: this.sort.by,
          type: this.filter.type,
          source: this.filter.source,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    handleModalExcel() {
      this.modalShowExcel = true
    },
    submit() {
      this.$refs.addContact.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          if (this.frm.name) formData.append('name', this.frm.name)
          if (this.frm.email) formData.append('email', this.frm.email)
          if (this.frm.phone_number) formData.append('phone', this.frm.phone_number)
          if (this.frm.type) formData.append('type', this.frm.type)
          if (this.frm.content) formData.append('content', this.frm.content)
          if (this.frm.status) formData.append('status', this.frm.status)
          if (this.frm.source) formData.append('source', this.frm.source)

          const url = this.editModal ? `/contacts/${this.editModal}` : '/contacts'
          if (this.editModal) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.loadItems()
              this.handleEdit()
              this.modalShow = false

              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    handleUploadContact() {
      this.isLoading = true
      const formData = new FormData()
      if (this.frm.fileExcel) {
        formData.append('fileExcel', this.frm.fileExcel)
        const url = 'contacts/upload-excel'
        this.$http({
          method: 'post',
          url,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.modalShowExcel = false
            this.loadItems()

            this.$bvToast.toast('Upload thành công', {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'success',
              solid: false,
            })
          })
        // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.$bvToast.toast('Upload thất bại', {
              title: this.$t('message.notify'),
              icon: 'BellIcon',
              variant: 'danger',
              solid: false,
            })
          }).finally(() => {
            this.isLoading = false
          })
      }
    },
    handleEdit(item) {
      this.modalShow = true
      this.modalName = 'Thêm mới'
      this.frm.id = this.frm.name = null
      this.frm.email = this.frm.phone_number = null
      this.frm.type = this.frm.content = this.frm.source = null
      if (item) {
        this.modalName = 'Chỉnh sửa'
        this.frm.id = item.id
        this.frm.name = item.name
        this.frm.email = item.email
        this.frm.phone_number = item.phone
        this.frm.status = item.status
        this.frm.type = item.type
        this.frm.content = item.content
        this.frm.source = item.source
      }
    },
    handleModalShow() {
      this.modalName = this.frm.id ? this.$t('update') : this.$t('create')
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    changeStatus(id, status) {
      this.isLoading = true
      this.$http.put(`/contacts/${id}`, { status })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$bvToast.toast(this.$t('message.update_success'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.$bvToast.toast(this.$t('message.update_err'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
          this.loadItems()
        })
    },
    convertDate(iosDate) {
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
  },
}
</script>
